import React from 'react';
import './Transaction.css';

const Transaction = props => {
    

    return (
        <li>
            <div className="conatiner"> 
            <div className="Thead">💰 {props.name}</div>
            <div className="uid">
                <ul>
                <li><i><u>INFO:</u>
                </i></li>
                <li>{props.user_id}</li>
                <li>{props.transactionDate}</li>
                </ul></div>
            <div className="date"></div>
            </div>
            <div>{props.type === 'deposit' ? (
                <span className="deposit"> + ₹{props.price} 📈</span>
            ) : (
                <span className="expense">
                    - ₹{props.price} 📉
                </span>
                
            )}</div>
            
        </li>
    );
}

export default Transaction;